import { acceptHMRUpdate, defineStore } from "pinia";
import { type Toast } from "@/models/toast";

export const useToastStore = defineStore("ToastStore", {
  state: () => {
    return {
      toast: {
        title: "",
        content: "",
        open: false,
        icon: "",
        type: "success",
      } as Toast,
    };
  },
  actions: {
    display({ title, content, icon, type }: Toast) {
      this.toast.title = title;
      this.toast.content = content;
      if (icon) {
        this.toast.icon = icon;
      }
      if (type) {
        this.toast.type = type;
      }
      this.toast.open = true;
      setTimeout(() => {
        this.reset();
      }, 3000);
    },
    reset() {
      this.toast.open = false;
      this.toast.title = "";
      this.toast.content = "";
      this.toast.type = "success";
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot));
}
